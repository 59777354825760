import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/zh"
import ArticleLayout from "../layouts/articlelayout"
import SEO from "../components/seo"

export default props => {
  return (
    <Layout location={props.location}>
      <SEO title={props.data.markdownRemark.frontmatter.title} description={props.data.markdownRemark.frontmatter.description} lang="zh" />
      <ArticleLayout data={props.data}/>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date
        tags
        price
        author
        description
      }
    }
  }
`
