module.exports = {
  selectLanguage: "选择你的语言",
  displayName: "中国人",
  categories: "分类目录",
  read: "读",
  price: "价钱",
  lastUpdate: "最后更新",
  free: "现在免费提供",
  Software: "软件",
  "Machine Learning": "机器学习",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "标签",
  Home: "主页",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "联系表",
  "Cancel": "取消",
  "Submit": "提交",
  "Contact Message": "如果您对我们的网站有任何反馈意见，我们将不胜感激。",
  "Flutter": "Flutter"
}
